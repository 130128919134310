import { CartTicket, CartTicketCategory } from '../types/CartType'

export function getCartTicketCategory(cartTicket: CartTicket): CartTicketCategory {
  let itemType: CartTicketCategory = 'extras'
  if (cartTicket.tags.includes('hotels')) itemType = 'hotels'
  if (cartTicket.tags.includes('rooms')) itemType = 'rooms'
  else if (cartTicket.title.toLowerCase().includes('express')) itemType = 'expressPasses'
  else if (cartTicket.title.toLowerCase().match(/[23] park|all parks/)) itemType = 'tickets'
  return itemType
}


export const cartTicketCategories: Array<CartTicketCategory> = [
  "hotels",
	'rooms',
  "tickets",
  "expressPasses",
  "extras",
]


/**
 * Arrange cart tickets by category, product and date.
 *
 * Returns a structure like this:
 *
 *     tickets:
 *       234771:
 *         2022: [{…}]
 *         2023: [{…}]
 *     expressPasses:
 *       284254:
 *         2022-03-24: [{…}]
 *         2022-03-29: [{…}]
 *       284256:
 *         2022-03-27: [{…}]
 *     extras: {}
 *     hotelRooms: {}
 */
type byDateType = {[key: string]: CartTicket[]}
type byProductType = {[key: string]: byDateType}
type CartTicketsByCategoryAndProduct = {
  tickets: byProductType,
  expressPasses: byProductType,
  extras: byProductType,
  hotels: byProductType,
	rooms: byProductType,
}
export function groupCartTicketsByCategoryAndProductAndDate(cartTickets: CartTicket[]): CartTicketsByCategoryAndProduct {

  // Organise tickets by date:
  const byDate: byDateType = {}
  cartTickets.forEach(t => {
    let date = 'none'
    if (t.year) date = t.year
    else if (t.date) date = t.date
    if (!(date in byDate)) byDate[date] = []
    byDate[date].push(t)
  })

  // Organise date groups by Product id:
  const byProduct: byProductType = {}
  Object.getOwnPropertyNames(byDate).forEach(date => {
    for (const ticket of byDate[date]) {
      const productId = ticket.product_id
      if (!(productId in byProduct)) byProduct[productId] = {}
      if (!(date in byProduct[productId])) byProduct[productId][date] = []
      byProduct[productId][date].push(ticket)
    }
  })

  // Organise Product groups by category:
  const items: CartTicketsByCategoryAndProduct = {
    tickets: {},
    expressPasses: {},
    extras: {},
    hotels: {},
		rooms: {},
  }
  Object.getOwnPropertyNames(byProduct).forEach(productId => {
    const firstDate = Object.getOwnPropertyNames(byProduct[productId])[0]
    const itemType = getCartTicketCategory(byProduct[productId][firstDate][0])
    items[itemType][productId] = byProduct[productId]
  })

  return items
}
